import { cncMiniLazyload } from '@default/scripts/modules/lazy-load/cncMiniLazyload';
import { bleskHeader } from '@default/scripts/modules/blesk-header';

/** Zpracovava lazyloading obrazku */
cncMiniLazyload();

/**
 * Init scriptu pro hlavicku Blesku:
 *    - otvirani velke navigace
 *    - prepnuti do sticky stavu
 *    - prizpusobeni poctu polozek u sticky menu
 *    - posouvani secondary menu (pokud existuje) do sticky headeru
 */
bleskHeader();

/**
 * Modul loadMore inituje a nastavuje komponentu LoadMore, ktera zajistuje asynchronni nacteni obsahu
 *
 * Dynamicky import modulu, pokud existuje element, na ktery se ma aplikovat
 */
const dynamicLoadMore = async () => {
    /** @type {NodeListOf<HTMLElement>} */
    const loadMoreElements = document.querySelectorAll('[data-load-more]');

    if (loadMoreElements.length) {
        const { loadMore } = await import(
            /* webpackChunkName: 'chunk-load-more' */
            './modules/load-more'
        );

        loadMore(loadMoreElements);
    }
};

/**
 * Modul writerList zajistuje animovane otevirani popupu se seznamem autoru po najeti mysi
 *
 * Dynamicky import modulu, pokud existuje element, na ktery se ma aplikovat
 */
const dynamicWriterList = async () => {
    /** @type {HTMLElement} */
    const writersElement = document.querySelector('.writer-list');

    if (writersElement) {
        const { writerList } = await import(
            /* webpackChunkName: 'chunk-writer-list' */
            './modules/writer-list'
        );

        writerList(writersElement);
    }
};

/**
 * Modul ipadChangeViewport zajistuje na iPadu upravu meta tagu viewport a pridani provizornich CSS
 *
 * Dynamicky import modulu, pokud je detekován iPad
 */
const dynamicIpadChangeViewport = async () => {
    if (
        document.querySelector('.blesk-header--responsive') === null &&
        document.documentElement.clientWidth <= 1024 &&
        navigator.userAgent.match(/iPad/i)
    ) {
        const { ipadChangeViewport } = await import('./modules/ipad-change-viewport');

        ipadChangeViewport();
    }
};

/**
 * Modul electionBanner zajistuje inicializaci a ovladani volebnich banneru
 *
 * Dynamicky import modulu, pokud existuje element, na ktery se ma aplikovat
 */
const dynamicElectionBannerList = async () => {
    /** @type {NodeListOf<HTMLElement>} */
    const electionBannerElements = document.querySelectorAll('div[id^=electionsBanner]');

    if (electionBannerElements.length > 0) {
        const { configureElectionBanner } = await import(
            /* webpackChunkName: 'chunk-election-banner-list' */
            '@default/scripts/modules/elections/electionBanner'
        );

        electionBannerElements.forEach((banner) => {
            configureElectionBanner(window.bannersProps[banner.id]);
        });
    }
};

/**
 * Modul electionSearch zajistuje inicializaci a ovladani volebniho vyhledavani
 *
 * Dynamicky import modulu, pokud existuje element, na ktery se ma aplikovat
 */
const dynamicElectionSearchList = async () => {
    /** @type {NodeListOf<HTMLElement>} */
    const electionSearchElements = document.querySelectorAll('.city-search');

    if (electionSearchElements.length > 0) {
        const { configureElectionSearch } = await import(
            /* webpackChunkName: 'chunk-election-search-list' */
            '@default/scripts/modules/elections/electionSearch'
        );

        electionSearchElements.forEach((searchItem) => {
            configureElectionSearch(searchItem);
        });
    }
};

/**
 * IDVERT
 */
const dynamicIdvertCompensation = async () => {
    if (document.querySelector('.idvert-wrapper')) {
        const { idvertCompensation } = await import(
            /* webpackChunkName: 'chunk-idvert-compensation' */
            './modules/idvert-compensation'
        );

        idvertCompensation();
    }
};

/**
 * Anketa s přihlášením pro zobrazení výsledku.
 */
const dynamicEnquiry = async () => {
    const enquiryElements = document.querySelectorAll('.enquiry-bars');

    if (enquiryElements && enquiryElements.length) {
        const { enquiryBars } = await import(
            /* webpackChunkName: 'chunk-enquiry-bars' */
            '@default/scripts/modules/enquiryBars/enquiryBars'
        );

        enquiryBars(enquiryElements);
    }
};

/**
 * RECOMBEE | Načítání článků
 */
const dynamicRecombeeArticles = async () => {
    const recombeeWrappers = document.querySelectorAll('[data-recombee]');

    if (recombeeWrappers && recombeeWrappers.length) {
        const { renderArticles } = await import(
            /* webpackChunkName: 'chunk-recombee-articles' */
            './modules/renderArticles'
        );

        renderArticles(recombeeWrappers);
    }
};

/** Spusteni dynamickych importu */
const loadDynamicImports = () => {
    dynamicLoadMore();
    dynamicWriterList();
    dynamicIpadChangeViewport();
    dynamicElectionBannerList();
    dynamicElectionSearchList();
    dynamicIdvertCompensation();
    dynamicEnquiry();
    dynamicRecombeeArticles();
};

loadDynamicImports();
