/* jshint esversion: 6 */

const LAZY_LOAD_SELECTOR = '.lazyload';

/**
 * @param {NodeListOf<HTMLImageElement>} images
 */
const animatedPlaceholder = (images) => {
  [...images].forEach((image) => {
    /** @type {HTMLElement} */
    const parentEl = image.parentElement;

    image.addEventListener('load', () => {
      parentEl.classList.add('item-loaded');
    });

    image.addEventListener('error', () => {
      parentEl.classList.remove('loading-placeholder');
      parentEl.classList.add('item-loaded-error');
    });
  });
};

/**
 * Replace data-src for src
 *
 * @param {NodeListOf<HTMLImageElement>} images
 */
const ieFallbackDataSrcReplacement = (images) => {
  [...images].forEach((image) => {
    image.setAttribute('src', image.getAttribute('data-src'));
  });
};

/**
 * Wrapper for https://github.com/VelociraptorCZE/MiniLazyload
 * @param {Object} [props]
 * @param {String} [props.rootMargin]
 */
export const cncMiniLazyload = async (props = {}) => {
  /** @type {NodeListOf<HTMLImageElement>} */
  const lazyLoadImages = document.querySelectorAll(LAZY_LOAD_SELECTOR);

  /* Fallback for IE10 */
  if (navigator.userAgent.match(/MSIE 10/)) {
    ieFallbackDataSrcReplacement(lazyLoadImages);

    /* Animated placeholder */
    animatedPlaceholder(lazyLoadImages);

    return;
  }

  animatedPlaceholder(lazyLoadImages);

  const { default: MiniLazyload } = await import(
    /* webpackChunkName: 'chunk-MiniLazyload' */
    './MiniLazyload'
  );

  new MiniLazyload({ rootMargin: '500px', ...props }, LAZY_LOAD_SELECTOR);
};
